import { IconCommerceDeliveryRobot } from "@s-group/design-system-icons"
import { Loader } from "@s-group/design-system-components"

export const LoadingAnimation = () => {
    return (
        <div className={"loading-container"} data-testid={"loading-animation"}>
            <IconCommerceDeliveryRobot color={"#007841"} />
            &nbsp;
            <IconCommerceDeliveryRobot color={"#007841"} />
            &nbsp;
            <IconCommerceDeliveryRobot color={"#007841"} />
        </div>
    )
}

export const LoadingAnimationPlain = () => {
    return <Loader aria-label="Loading" size="medium" type="circle" />
}
