import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useAccount,
    useMsal
} from "@azure/msal-react"
import { Paragraph } from "../ui-components/Paragraph"
import { Button } from "@s-group/design-system-components"
import { useEffect, useState } from "react"
import { appScope, backendUrl, getAxiosConfig, loginRequest } from "../config"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import axios from "axios"

export function DebugPage() {
    const { t, i18n } = useTranslation()
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    const [connStatus, setConnStatus] = useState("")
    const [accessToken, setAccessToken] = useState("")

    useEffect(() => {
        if (account && account.name) {
            instance
                .acquireTokenSilent({
                    scopes: [appScope]
                })
                .then((response) => {
                    if (response) {
                        console.log(response.accessToken)
                        setAccessToken(response.accessToken)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    instance.loginRedirect(loginRequest)
                })
        }
    }, [instance, account])

    const testConnection = () => {
        axios
            .get(backendUrl, getAxiosConfig(accessToken))
            .then((response) => {
                console.log(response)
                setConnStatus("[" + response.status + "]")
            })
            .catch((reason) => {
                console.log(reason)
                setConnStatus(reason.toString())
            })
    }

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang)
        localStorage.setItem("language", lang)
    }

    return (
        <>
            <AuthenticatedTemplate>
                <Paragraph>{t("Debug")}</Paragraph>
                <Paragraph>
                    <Link to={"product-api"}>Retail Product API</Link>
                </Paragraph>
                <p>ravintotietoja: 100000678, reguPerm: 100030619</p>
                {/* Retail product search link disabled for now }
                {<Paragraph><Link to={"product-search"}>Retail Product Search</Link></Paragraph>}
                {*/}
                <Button
                    onClick={() => changeLanguage("fi")}
                    text={"Suomeksi"}
                />
                <Button
                    onClick={() => changeLanguage("en")}
                    text={"In English"}
                />
                <br />
                <Button
                    onClick={() => testConnection()}
                    text={"Test backend connection"}
                />
                <Paragraph>Connection status: {connStatus}</Paragraph>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Paragraph>
                    Please log in with your SOK Azure AD account to use the
                    system.
                </Paragraph>
            </UnauthenticatedTemplate>
        </>
    )
}
