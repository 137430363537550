import { useMsal } from "@azure/msal-react"
import { Button } from "@s-group/design-system-components"
import { t } from "i18next"

export const SignOutButton = () => {
    const { instance } = useMsal()

    const handleLogout = () => {
        instance.logoutRedirect()
    }

    return (
        <Button
            id={"sign-out-button"}
            sizing={"small"}
            variant={"plain"}
            color={"primary"}
            onClick={handleLogout}
            key="loginRedirect"
        >
            {t("Common.logout")}
        </Button>
    )
}
