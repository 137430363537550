import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../config"
import { Button } from "@s-group/design-system-components"

export const SignInButton = () => {
    const { instance } = useMsal()

    const handleLogin = () => {
        instance.loginRedirect(loginRequest)
    }

    return (
        <Button
            id={"sign-in-button"}
            sizing={"small"}
            color={"primary"}
            variant={"filled"}
            onClick={handleLogin}
            key="loginRedirect"
        >
            Login
        </Button>
    )
}
