import { loginRequest, graphConfig } from "../config"
import { msalInstance } from "../index"

export async function callMsGraph() {
    const account = msalInstance.getActiveAccount()
    if (!account) {
        throw Error(
            "No active account! Verify a user has been signed in and setActiveAccount has been called."
        )
    }

    const tokenResponse = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    })

    const headers = new Headers()
    const bearer = `Bearer ${tokenResponse.accessToken}`

    headers.append("Authorization", bearer)

    const options = {
        method: "GET",
        headers: headers
    }

    return fetch(graphConfig.graphMeEndpoint, options)
        .then((response) => response.json())
        .catch((error) => console.log(error))
}
