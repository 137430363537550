type EnvironmentDetails = {
    environmentName: string
    backgroundColor: string
}

const getEnvironmentDetails = (): EnvironmentDetails => {
    const environment = process.env.REACT_APP_ENVIRONMENT_NAME
    if (environment === "dev") {
        return {
            environmentName: "Dev",
            backgroundColor: "rgba(246, 244, 232, 1)"
        }
    } else if (environment === "test") {
        return {
            environmentName: "Test",
            backgroundColor: "rgba(180, 210, 255, 0.4)"
        }
    } else if (environment === "preprod") {
        return {
            environmentName: "Preprod",
            backgroundColor: "rgba(234, 244, 234, 1)"
        }
    } else {
        return {
            environmentName: "",
            backgroundColor: "#fff"
        }
    }
}

export const environmentName: string = getEnvironmentDetails().environmentName
export const backgroundColor: string = getEnvironmentDetails().backgroundColor
