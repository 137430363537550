export enum PricingLevelPriceListType {
    EK = "EEKOO",
    EP = "EEPEE",
    HA = "Hämeenmaa",
    HE = "HOK-Elanto",
    JU = "Jukola",
    KE = "Keskimaa",
    KL = "Keula",
    KM = "Koillismaa",
    KP = "KPO",
    KS = "Kymen Seutu",
    LA = "Lappajärven OK",
    MA = "Maakunta",
    PA = "PeeÄssä",
    PI = "Pirkanmaa",
    PK = "PKO",
    SA = "Satakunta",
    SS = "SSO",
    SU = "Suur-Savo",
    TO = "TOK",
    VO = "Varuboden-Osla"
}

export const getPricingLevelPriceListType = (
    pricingLevelPriceListType: string
): string => {
    switch (pricingLevelPriceListType) {
        case "EK":
            return PricingLevelPriceListType.EK
        case "EP":
            return PricingLevelPriceListType.EP
        case "HA":
            return PricingLevelPriceListType.HA
        case "HE":
            return PricingLevelPriceListType.HE
        case "JU":
            return PricingLevelPriceListType.JU
        case "KE":
            return PricingLevelPriceListType.KE
        case "KL":
            return PricingLevelPriceListType.KL
        case "KM":
            return PricingLevelPriceListType.KM
        case "KP":
            return PricingLevelPriceListType.KP
        case "KS":
            return PricingLevelPriceListType.KS
        case "LA":
            return PricingLevelPriceListType.LA
        case "MA":
            return PricingLevelPriceListType.MA
        case "PA":
            return PricingLevelPriceListType.PA
        case "PI":
            return PricingLevelPriceListType.PI
        case "PK":
            return PricingLevelPriceListType.PK
        case "SA":
            return PricingLevelPriceListType.SA
        case "SS":
            return PricingLevelPriceListType.SS
        case "SU":
            return PricingLevelPriceListType.SU
        case "TO":
            return PricingLevelPriceListType.TO
        case "VO":
            return PricingLevelPriceListType.VO
        case "11":
            return "Klusteri"
        case "12":
            return "Klusteri"
        default:
            return pricingLevelPriceListType
    }
}
