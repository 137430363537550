import { Site, siteArrayToMap } from "../utils/SitesApi"
import { useTranslation } from "react-i18next"
import { getNonEmptyColumns } from "../utils/SearchFormUtil"
import { formatTimestamp } from "../utils/DateUtils"

interface StoreAssortmentsTableProps {
    assortments: any
    sites: Site[]
}

export const StoreAssortmentsTable = (props: StoreAssortmentsTableProps) => {
    const { t } = useTranslation()
    const { assortments } = props
    if (!assortments) {
        return null
    } else if (assortments.length === 0) {
        return <p>{t("Assortments.noAssortments")}</p>
    }
    const sitesMap = siteArrayToMap(props.sites)
    const nonEmptyCols = getNonEmptyColumns(assortments)
    // In 99% of the cases, the lmDescription equals site name when case is ignored, so we can save a lot of space
    // from the page by omitting the lmDescription. (Site names are always uppercase).
    const descriptionNeeded = isAnyDescriptionDifferentThanSiteName(
        assortments,
        sitesMap
    )
    return (
        <div className={"table-wrapper"}>
            <p>{t("Assortments.storeAssortmentsHelperText")}</p>
            <table>
                <thead>
                    <tr>
                        <th>{t("Common.siteId")}</th>
                        <th>{t("Common.siteName")}</th>
                        <th>{t("Assortments.unit")}</th>
                        <th>{t("Assortments.layoutModuleId")}</th>
                        {nonEmptyCols.has("promotionId") && (
                            <th>{t("Assortments.promotion")}</th>
                        )}
                        <th>{t("Common.validFrom")}</th>
                        <th>{t("Common.validTo")}</th>
                        <th>{t("Assortments.versionNumber")}</th>
                        <th>{t("Common.modifiedAt")}</th>
                        {/* Some columns are often empty so hiding them will save space from the page if they are not needed. */}
                        {nonEmptyCols.has("lmDepth") && (
                            <th>{t("Assortments.lmDepth")}</th>
                        )}
                        {descriptionNeeded && (
                            <th>{t("Assortments.lmDescription")}</th>
                        )}
                        <th>{t("Assortments.lmHierarchyNode")}</th>
                        {nonEmptyCols.has("lmRole") && (
                            <th>{t("Assortments.lmRole")}</th>
                        )}
                        <th>{t("Assortments.lmSeries")}</th>
                        {nonEmptyCols.has("lmPriority") && (
                            <th
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        "Assortments.lmPriorityWithSoftHyphen",
                                        {
                                            interpolation: {
                                                escapeValue: false
                                            }
                                        }
                                    )
                                }}
                            />
                        )}
                        {nonEmptyCols.has("lmSpecialLmType") && (
                            <th>{t("Assortments.lmSpecialLmType")}</th>
                        )}
                        {nonEmptyCols.has("versionComponent") && (
                            <th>{t("Assortments.versionComponent")}</th>
                        )}
                        {nonEmptyCols.has("versionDisplayProductSokId") && (
                            <th>
                                {t("Assortments.versionDisplayProductSokId")}
                            </th>
                        )}
                        <th>{t("Assortments.versionAssignedAt")}</th>
                        <th>{t("Assortments.versionAssortmentLevel")}</th>
                        {nonEmptyCols.has("versionLmVariant") && (
                            <th>{t("Assortments.versionLmVariant")}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {assortments.map((p: any) => {
                        const site = sitesMap.get(p.siteId)
                        const siteName = site ? site.siteName : ""
                        return (
                            <tr
                                key={"chain-assortment-" + p.id}
                                title={"ID: " + p.id}
                            >
                                <td>{p.siteId}</td>
                                <td>{siteName}</td>
                                <td>{p.unit}</td>
                                <td>{p.layoutModuleId}</td>
                                {nonEmptyCols.has("promotionId") && (
                                    <td>{p.promotionId}</td>
                                )}
                                <td>{p.validFrom}</td>
                                <td>{p.validTo}</td>
                                <td>{p.versionNumber}</td>
                                <td>{formatTimestamp(p.sourceModifiedAt)}</td>
                                {nonEmptyCols.has("lmDepth") && (
                                    <td>{p.lmDepth}</td>
                                )}
                                {descriptionNeeded && (
                                    <td>{p.lmDescription}</td>
                                )}
                                <td>{p.lmHierarchyNode}</td>
                                {nonEmptyCols.has("lmRole") && (
                                    <td>{p.lmRole}</td>
                                )}
                                <td>{p.lmSeries}</td>
                                {nonEmptyCols.has("lmPriority") && (
                                    <td>{p.lmPriority}</td>
                                )}
                                {nonEmptyCols.has("lmSpecialLmType") && (
                                    <td>{p.lmSpecialLmType}</td>
                                )}
                                {nonEmptyCols.has("versionComponent") && (
                                    <td>{p.versionComponent ? "X" : ""}</td>
                                )}
                                {nonEmptyCols.has(
                                    "versionDisplayProductSokId"
                                ) && <td>{p.versionDisplayProductSokId}</td>}
                                <td>{p.versionAssignedAt}</td>
                                <td>{p.versionAssortmentLevel}</td>
                                {nonEmptyCols.has("versionLmVariant") && (
                                    <td>{p.versionLmVariant}</td>
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

const isAnyDescriptionDifferentThanSiteName = (
    assortments: any[],
    sites: Map<string, Site>
) => {
    return assortments.some((a) => {
        const site = sites.get(a.siteId)
        const siteName = site ? site.siteName.trim().toLowerCase() : undefined
        const lmDescription = a.lmDescription
            ? a.lmDescription.trim().toLowerCase()
            : undefined
        return siteName !== lmDescription
    })
}
