import { useTranslation } from "react-i18next"
import { BonusBuyUtils } from "./BonusBuyUtils"
import { ScenarioTable } from "./ScenarioTable"

interface Scenario3TableProps {
    bonusBuys: any
    sitesMap: any
}

// Scenario 3: Buy Y products and get Z extra for free
export const Scenario3Table = (props: Scenario3TableProps) => {
    const { t } = useTranslation()
    const { bonusBuys } = props

    if (!bonusBuys || bonusBuys.length === 0) {
        return null
    }

    return bonusBuys.map((bb: any, index: number) => {
        if (bb["sourceDeletedAt"] !== null) {
            return null
        }

        const bonusBuyMap = new Map<string, string | undefined>()
        bonusBuyMap.set(t("BonusBuys.id"), bb["bonusBuyId"])
        bonusBuyMap.set(t("BonusBuys.promotionId"), bb["promotionId"])
        bonusBuyMap.set(
            t("BonusBuys.description"),
            bb["bonusBuyDetails"]["description"]
        )
        bonusBuyMap.set(
            t("BonusBuys.customerType"),
            bb["bonusBuyDetails"]["customerType"]
        )
        bonusBuyMap.set(
            t("BonusBuys.validFrom"),
            bb["bonusBuyDetails"]["validFrom"]
        )
        bonusBuyMap.set(
            t("BonusBuys.validTo"),
            bb["bonusBuyDetails"]["validTo"]
        )
        bonusBuyMap.set(
            t("BonusBuys.sites"),
            BonusBuyUtils.getSiteIdsString(
                bb["bonusBuyDetails"]["siteIds"],
                props.sitesMap,
                "\n"
            )
        )
        const buyDetails = bb["bonusBuyDetails"]["buyDetails"]
        bonusBuyMap.set(t("BonusBuys.buyQuantity"), buyDetails["quantity"])
        bonusBuyMap.set(
            t("BonusBuys.buyItems"),
            BonusBuyUtils.getItemsString(buyDetails["items"], "\n")
        )
        bonusBuyMap.set(
            t("BonusBuys.buyMaterialGroups"),
            buyDetails["materialGroups"]?.join("\n")
        )

        const receiveDetails = bb["bonusBuyDetails"]["receiveDetails"]
        receiveDetails.forEach((receiveItem: any, receiveIndex: number) => {
            const index = receiveIndex + 1
            bonusBuyMap.set(
                t("BonusBuys.receiveDiscount") +
                    BonusBuyUtils.getReceiveItemIndex(
                        index,
                        receiveDetails.length
                    ),
                receiveItem["discount"] + " " + receiveItem["discountUnit"]
            )
            bonusBuyMap.set(
                t("BonusBuys.receiveQuantity") +
                    BonusBuyUtils.getReceiveItemIndex(
                        index,
                        receiveDetails.length
                    ),
                receiveItem["quantity"]
            )
            bonusBuyMap.set(
                t("BonusBuys.receiveItems") +
                    BonusBuyUtils.getReceiveItemIndex(
                        index,
                        receiveDetails.length
                    ),
                BonusBuyUtils.getItemsString(receiveItem["items"], "\n")
            )
            bonusBuyMap.set(
                t("BonusBuys.receiveMaterialGroups") +
                    BonusBuyUtils.getReceiveItemIndex(
                        index,
                        receiveDetails.length
                    ),
                receiveItem["materialGroups"]?.join("\n")
            )
        })

        return ScenarioTable({
            bonusBuysMap: bonusBuyMap,
            tableHeader: "BonusBuys.scenario3Description",
            id: "BonusBuysScenario3Table-" + index
        })
    })
}
