import axios from "axios"
import { backendUrl, getAxiosConfig } from "../config"

export interface SiteMetadata {
    retailPlantId?: string | null
}

export interface Site {
    siteCode: string
    siteName: string
    startDate?: string | null
    endDate?: string | null
    corporationCode?: string | null
    companyCode: string
    siteChainCode?: string | null
    siteChainName?: string | null
    businessOpeningDate?: string | null
    businessClosingDate?: string | null
    streetAddress?: string | null
    postalCode?: string | null
    postalOffice?: string | null
    municipalityCode?: string | null
    municipalityName?: string | null
    coordinateLat?: number | null
    coordinateLon?: number | null
    meta: SiteMetadata
    siteInMarttiUntilDate?: string | null
}

export const siteArrayToMap = (arr: Site[]) =>
    new Map(arr.map((obj) => [obj.siteCode, obj]))

export const getSites = (token: string) => {
    return new Promise<Site[]>((resolve, reject) => {
        axios
            .get(backendUrl + "/sites", getAxiosConfig(token))
            .then((response) => {
                if (response.status === 200) {
                    const sites = response.data as Site[]
                    resolve(sites)
                }
            })
            .catch((reason) => {
                console.log("Failed to load sites, reason = " + reason)
                reject(reason)
            })
    })
}
