import { useTranslation } from "react-i18next"
import { Site } from "../utils/SitesApi"

export interface StockQuantitiesEcomFilteringLogsTableProps {
    filteringLogsMap: any
    sitesMap: Map<string, Site>
}

export const StockQuantitiesEcomFilteringLogsComponent = (
    props: StockQuantitiesEcomFilteringLogsTableProps
) => {
    const { t } = useTranslation()
    if (props.filteringLogsMap === undefined) {
        return null
    } else if (Object.keys(props.filteringLogsMap).length === 0) {
        return (
            <div>
                <h2>{t("StockQuantities.filteringLogsTitle")}</h2>
                <p>{t("StockQuantities.filteringLogsNoSitesFiltered")}</p>
            </div>
        )
    }

    return (
        <div>
            <h2>{t("StockQuantities.filteringLogsTitle")}</h2>
            <p>{t("StockQuantities.filteringLogsDescription")}</p>
            <table>
                <thead>
                    <tr>
                        <th>{t("Common.id")}</th>
                        <th>{t("StockQuantities.siteName")}</th>
                        <th>{t("StockQuantities.filteringLogsReasonCode")}</th>
                        <th>
                            {t(
                                "StockQuantities.filteringLogsReasonDescription"
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(props.filteringLogsMap).map((key: string) => {
                        const log = props.filteringLogsMap[key]
                        const locationId = log["locationId"]
                        const site = props.sitesMap.get(locationId)
                        const siteId = site ? site.siteCode : locationId
                        const siteName = site ? site.siteName : ""

                        return (
                            <tr key={"balance-filteringLogs-" + key}>
                                <td>{siteId}</td>
                                <td>{siteName}</td>
                                <td>{log["reasonCode"]}</td>
                                <td>{log["reasonDescription"]}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
