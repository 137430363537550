interface DepositListProps {
    deposits?: any
}

export const DepositList = (props: DepositListProps) => {
    if (!props.deposits) {
        return null
    }
    return <ul>{props.deposits.map(getDepositItem)}</ul>
}

const getDepositItem = (deposit: any) => {
    if (
        deposit &&
        deposit.quantity &&
        deposit.prices &&
        deposit.prices.length > 0
    ) {
        return deposit.prices.map((d: any) => {
            return (
                <li key={"deposit-" + d.id}>
                    {deposit.quantity} x {d.amount} {d.currency}, {d.validFrom}{" "}
                    — {d.validTo} (id: {d.sokId})
                </li>
            )
        })
    }
    return null
}
