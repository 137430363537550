import { useTranslation } from "react-i18next"
import { useAccount, useMsal } from "@azure/msal-react"
import { useCallback, useEffect, useState } from "react"
import { appRoles } from "../config"
import { AxiosError } from "axios"
import { useParams } from "react-router-dom"
import { isValidSokIdOrEan, ProductSearchInput } from "./ProductSearchInput"
import { GenericSearchResult } from "../domain/GenericSearchResult"
import { LoadingAnimation } from "./LoadingAnimation"
import { userHasRole } from "../utils/AuthUtil"
import { RetailProductApiSearchErrorMessage } from "./RetailProductApiSearchErrorMessage"
import {
    getAccessToken,
    getAllBySokIdAndEan,
    handleSearchError,
    searchFromRetailProductApiBySokIdUsingPost,
    SokIdUrlParam
} from "../utils/SearchFormUtil"
import { getSites, Site } from "../utils/SitesApi"
import { ChainAssortmentsTable } from "./ChainAssortmentsTable"
import { StoreAssortmentsTable } from "./StoreAssortmentsTable"
import { PreviousSearchList } from "./PreviousSearchList"
import { handleSetSearchToLocalStorage } from "../utils/LocalStorageUtil"
import MultipleResults from "./MultipleResults"
import { MultipleSearchResults } from "../domain/MultipleSearchResults"

export const AssortmentsSearchForm = () => {
    const { t } = useTranslation()
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    const urlParams = useParams<SokIdUrlParam>()
    const hasExtendedRole = userHasRole(appRoles.ExtendedUser, account)
    const [multipleSearchResults, setMultipleSearchResults] =
        useState<MultipleSearchResults | null>(null)
    const [chainAssortments, setChainAssortments] =
        useState<GenericSearchResult>({ inProgress: false })
    const [storeAssortments, setStoreAssortments] =
        useState<GenericSearchResult>({ inProgress: false })
    const [accessToken, setAccessToken] = useState("")
    const [sites, setSites] = useState<Site[]>([])

    const handleChainSearchError = (error: Error | AxiosError) => {
        handleSearchError(error, (res) => setChainAssortments(res))
    }
    const handleStoreSearchError = (error: Error | AxiosError) => {
        handleSearchError(error, (res) => setStoreAssortments(res))
    }

    const searchAssortmentsBySokId = useCallback(
        (sokId: string, token: string, ean: string | null) => {
            setChainAssortments({ inProgress: true })
            setStoreAssortments({ inProgress: true })
            searchFromRetailProductApiBySokIdUsingPost(
                "chainAssortments",
                sokId,
                token,
                "/assortments/" + sokId
            )
                .then((res) => setChainAssortments(res))
                .catch(handleChainSearchError)
            searchFromRetailProductApiBySokIdUsingPost(
                "storeAssortments",
                sokId,
                token,
                "/assortments/" + sokId
            )
                .then((res) => {
                    setStoreAssortments(res)
                    handleSetSearchToLocalStorage(res, ean, sokId)
                })
                .catch(handleStoreSearchError)
        },
        []
    )

    const search = useCallback(
        (searchTerm: string, token: string) => {
            if (!searchTerm || !isValidSokIdOrEan(searchTerm)) {
                return
            }
            setMultipleSearchResults(null)
            searchTerm = searchTerm.trim()

            getAllBySokIdAndEan(searchTerm, token, "/assortments/")
                .then((result) => {
                    const numberOfResults = result.getNumberOfResults()
                    if (numberOfResults === 0) {
                        setChainAssortments({
                            inProgress: false,
                            statusCode: 404
                        })
                        setStoreAssortments({
                            inProgress: false,
                            statusCode: 404
                        })
                    } else if (numberOfResults === 1) {
                        const searchResult = result.getOneResultOrNull()
                        if (searchResult !== null) {
                            searchAssortmentsBySokId(
                                searchResult.sokId!,
                                token,
                                searchResult.data.ean
                            )
                        }
                    } else {
                        setMultipleSearchResults(result)
                        setChainAssortments({ inProgress: false })
                        setStoreAssortments({ inProgress: false })
                    }
                })
                .catch((error) => {
                    console.error(error)
                    handleSearchError(error, (res) => setStoreAssortments(res))
                    handleSearchError(error, (res) => setChainAssortments(res))
                })
        },
        [searchAssortmentsBySokId]
    )

    useEffect(() => {
        getAccessToken(account, instance).then((token) => {
            setAccessToken(token)
            getSites(token).then((response) => setSites(response))
            if (urlParams.sokId) {
                searchAssortmentsBySokId(urlParams.sokId, token, null)
            }
        })
    }, [instance, account, urlParams, searchAssortmentsBySokId])

    // RouteGuard makes sure this does not happen in the app, but this is needed for component tests.
    if (!hasExtendedRole) {
        return <p>{t("PermissionDenied.genericMessage")}</p>
    }
    return (
        <div>
            <h1>{t("Assortments.title")}</h1>
            <p>{t("Assortments.helpText")}</p>
            <PreviousSearchList site={"/assortments/"} />
            <ProductSearchInput
                initialValue={urlParams ? urlParams.sokId : ""}
                onSearch={(value) => {
                    search(value, accessToken)
                }}
                onKeyPress={(event) => {
                    if (event.key === "Enter") {
                        const target: any = event.target
                        const text: string = target.value
                        search(text, accessToken)
                    }
                }}
            />
            <br />
            <br />
            {multipleSearchResults ? (
                <MultipleResults
                    results={multipleSearchResults}
                    site={"/assortments/"}
                />
            ) : (
                <>
                    <h2>{t("Assortments.chainAssortments")}</h2>
                    {chainAssortments.inProgress && <LoadingAnimation />}

                    {chainAssortments.statusCode &&
                    chainAssortments.statusCode !== 200 ? (
                        <RetailProductApiSearchErrorMessage
                            searchResult={chainAssortments}
                        />
                    ) : (
                        <ChainAssortmentsTable
                            assortments={chainAssortments.data}
                        />
                    )}

                    <br />
                    <h2>{t("Assortments.storeAssortments")}</h2>
                    {storeAssortments.inProgress && <LoadingAnimation />}

                    {storeAssortments.statusCode &&
                    storeAssortments.statusCode !== 200 ? (
                        <RetailProductApiSearchErrorMessage
                            searchResult={storeAssortments}
                        />
                    ) : (
                        <StoreAssortmentsTable
                            assortments={storeAssortments.data}
                            sites={sites}
                        />
                    )}
                </>
            )}
        </div>
    )
}
