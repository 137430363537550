import React from "react"
import NavBar from "./NavBar"
import { backgroundColor } from "../utils/EnvironmentUtil"

export const PageLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <div>
            <NavBar />
            <br />
            <div
                id={"main-content"}
                style={{ backgroundColor }}
                className="width-limit"
            >
                {children}
            </div>
        </div>
    )
}
