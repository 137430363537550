import { GenericSearchResult } from "../domain/GenericSearchResult"
import { useTranslation } from "react-i18next"

interface RetailProductApiSearchErrorMessageProps {
    searchResult: GenericSearchResult
}

export const RetailProductApiSearchErrorMessage = (
    props: RetailProductApiSearchErrorMessageProps
) => {
    const { t } = useTranslation()
    const { searchResult } = props
    let message
    if (searchResult.statusCode === 401) {
        message = t("RetailProductApi.searchErrorMessages.statusCode401")
    } else if (searchResult.statusCode === 404) {
        message = t("RetailProductApi.searchErrorMessages.statusCode404")
    } else {
        message = t("RetailProductApi.searchErrorMessages.statusCode5xx")
    }
    return <p>{message}</p>
}
