import { DepositList } from "./DepositList"
import { Site, siteArrayToMap } from "../utils/SitesApi"
import { useTranslation } from "react-i18next"
import { getNonEmptyColumns } from "../utils/SearchFormUtil"
import { formatTimestamp } from "../utils/DateUtils"
import { getPricingLevelPriceListType } from "../utils/PricingLevelPriceListTypeUtil"

export interface StoreRetailPricesTableProps {
    prices: any
    sites: Site[]
}

export const StoreRetailPricesTable = (props: StoreRetailPricesTableProps) => {
    const { t } = useTranslation()
    const { prices } = props
    if (!prices) {
        return null
    } else if (prices.length === 0) {
        return <p>{t("Prices.noPrices")}</p>
    }
    const nonEmptyCols = getNonEmptyColumns(prices)
    // Hide pricing unit if it is 1 in every row
    if (allPricingUnitsEqual1(prices)) {
        nonEmptyCols.delete("pricingUnit")
    }
    const sitesMap = siteArrayToMap(props.sites)
    return (
        <div className={"table-wrapper"}>
            <p>{t("Prices.storeRetailPricesHelperText")}</p>
            <table>
                <thead>
                    <tr>
                        <th>{t("Common.siteId")}</th>
                        <th>{t("Common.siteName")}</th>
                        <th>{t("Prices.amount")}</th>
                        <th>{t("Common.validFrom")}</th>
                        <th>{t("Common.validTo")}</th>
                        {/* Following words are quite long in Finnish, and we want to use the HTML code for soft
                        hyphen &shy; and it needs to be escaped for React AND i18next library. */}
                        <th
                            dangerouslySetInnerHTML={{
                                __html: t("Prices.salesUnitWithSoftHyphen", {
                                    interpolation: { escapeValue: false }
                                })
                            }}
                        />
                        {nonEmptyCols.has("pricingUnit") && (
                            <th
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        "Prices.pricingUnitWithSoftHyphen",
                                        {
                                            interpolation: {
                                                escapeValue: false
                                            }
                                        }
                                    )
                                }}
                            />
                        )}
                        <th>{t("Prices.salesOrg")}</th>
                        <th>{t("Prices.pricingLevelPriceListType")}</th>
                        <th>{t("Prices.pricingLevelSiteId")}</th>
                        {nonEmptyCols.has("deposits") && (
                            <th>{t("Prices.deposits")}</th>
                        )}
                        <th>{t("Common.modifiedAt")}</th>
                    </tr>
                </thead>
                <tbody>
                    {prices.map((p: any) => {
                        const site = sitesMap.get(p.siteId)
                        const siteName = site ? site.siteName : ""

                        return (
                            <tr
                                key={"store-retail-price-" + p.id}
                                title={"ID: " + p.id}
                            >
                                <td>{p.siteId}</td>
                                <td>{siteName}</td>
                                <td>{p.amount}</td>
                                <td>{p.validFrom}</td>
                                <td>{p.validTo}</td>
                                <td>{p.salesUnit}</td>
                                {nonEmptyCols.has("pricingUnit") && (
                                    <td>{p.pricingUnit}</td>
                                )}
                                <td>{p.pricingLevelChainSalesOrg}</td>
                                <td>
                                    {getPricingLevelPriceListType(
                                        p.pricingLevelPriceListType
                                    )}
                                </td>
                                <td>{p.pricingLevelSiteId}</td>
                                {nonEmptyCols.has("deposits") && (
                                    <td>
                                        <DepositList deposits={p.deposits} />
                                    </td>
                                )}
                                <td>{formatTimestamp(p.sourceModifiedAt)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export function allPricingUnitsEqual1(prices: any): boolean {
    return prices.every((p: any) => p.pricingUnit === "1")
}
