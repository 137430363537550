import { useTranslation } from "react-i18next"
import { useAccount, useMsal } from "@azure/msal-react"
import { useEffect, useState } from "react"
import { getAccessToken } from "../utils/SearchFormUtil"
import { getLocations, Location } from "../utils/LocationsApi"
import { EcomWarehousesTable } from "./EcomWarehousesTable"
import { LoadingAnimation } from "./LoadingAnimation"

export const EcomWarehouses = () => {
    const { t } = useTranslation()
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    const [locations, setLocations] = useState<Location[] | null>(null)
    const [isError, setIsError] = useState<boolean>(false)

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const token: string = await getAccessToken(account, instance)
                const locations = await getLocations(token)
                setLocations(locations)
            } catch (error) {
                setIsError(true)
            }
        }
        fetchLocations()
    }, [instance, account])

    if (isError) {
        return (
            <h1 data-testid={"locations-error"}>
                {t("EcomWarehouses.errorMessage")}
            </h1>
        )
    }

    if (locations === null) {
        return <LoadingAnimation />
    }

    const locationsAvailable: boolean = locations.length > 0
    if (!locationsAvailable) {
        return (
            <h1 data-testid={"no-locations"}>
                {t("EcomWarehouses.noLocations")}
            </h1>
        )
    }

    return (
        <div>
            <h1>{t("EcomWarehouses.title")}</h1>
            <p>{t("EcomWarehouses.description")}</p>
            <EcomWarehousesTable locations={locations} />
        </div>
    )
}
