import { useTranslation } from "react-i18next"
import { Site, siteArrayToMap } from "../utils/SitesApi"
import { StockQuantitiesEcomFilteringLogsComponent } from "./StockQuantitiesEcomFilteringLogsComponent"
import { StockQuantitiesEcomTable } from "./StockQuantitiesEcomTable"
import React from "react"

export interface StockQuantitiesEcomComponentProps {
    stockQuantities: any
    sites: Site[]
}

export const StockQuantitiesEcomComponent = (
    props: StockQuantitiesEcomComponentProps
) => {
    const { t } = useTranslation()
    const sitesMap = siteArrayToMap(props.sites)

    const productKeys = Object.keys(props.stockQuantities)
    if (productKeys.length === 0) {
        return null
    }
    const qtyMap = props.stockQuantities[productKeys[0]]
    const filteringLogsMap = qtyMap["filteringLogs"]

    if (qtyMap === undefined) {
        return null
    } else if (Object.keys(qtyMap).length === 0) {
        return <p>{t("StockQuantities.noResults")}</p>
    }

    return (
        <div>
            <StockQuantitiesEcomTable
                quantityMap={qtyMap}
                sitesMap={sitesMap}
            />
            <StockQuantitiesEcomFilteringLogsComponent
                filteringLogsMap={filteringLogsMap}
                sitesMap={sitesMap}
            />
        </div>
    )
}
